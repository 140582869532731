<template>
  <div id="home" class="pl-6 pr-6 pt-0">
    <v-div fluid>
      <v-alert prominent type="error" v-if="errored">
        <v-row align="center">
          <v-col class="grow">
            <div class="title">Error!</div>
            <div>
              Something went wrong, but don’t fret — let’s give it another shot.
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="getVideos">Take action</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <main v-else>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            v-for="(video, i) in loading ? 12 : videos"
            :key="i"
            class="mx-xs-auto mt-2"
          >
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <even-video-card
                v-if="video || isMobile"
                :card="{ maxWidth: '100%' }"
                :video="video"
                @input="showMessage"
              ></even-video-card>
              <!-- <odd-video-card
                  v-if="i%2 !== 0 && !isMobile"
                  :card="{ maxWidth: '100%' }"
                  :video="video"
                  @input="showMessage"
              ></odd-video-card> -->
            </v-skeleton-loader>
            <!--            <v-divider v-if="videos.length - 1 !== i" class="mt-3 mb-3" style="border-width: 3px;"></v-divider>-->
          </v-col>

          <!-- <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos available</p>
          </v-col> -->

          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading
              :force-use-infinite-wrapper="false"
              :identifier="infiniteId"
              @infinite="getVideos"
              ref="infiniteLoading"
            >
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="deep-orange"
                ></v-progress-circular>
              </div>
              <div slot="no-results">No videos available</div>
              <div slot="no-more">No more videos</div>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
        </v-row>
      </main>
      <v-snackbar v-model="snackbar" app top>
        {{ message }}
        <v-btn color="white" text @click="snackbar = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-snackbar>
    </v-div>
  </div>
</template>

<script>
//      if (typeof window !== 'undefined') {
//       var UserData = localStorage.getItem("user");
// }
// console.log(UserData);
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";

import EvenVideoCard from "@/components/EvenVideoCard";
// import OddVideoCard from "@/components/OddVideoCard";
import VideoService from "@/services/VideoService";
import UserService from "@/services/UserService";
import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
export default {
  name: "Home",
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    videos: [],
    page: "",
    isMobile: false,
    randomBoolean: false,
    snackbar: false,
    message: "",
    dataSearch: null,
    infiniteId: "",
    changingState: false,
  }),
  mounted() {
    this.checkMobile();
    this.AddtoDb();
  },
  computed: {
    searchData() {
      return this.$store.getters.getSearchData;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  watch: {
    searchData(data) {
      this.dataSearch = data;
      this.infiniteId = +new Date();

      this.videos = [];
    },
  },
  created() {
    this.randomBoolean = Math.random() < 0.5;
  },
  methods: {
    toggleLoading() {
      this.$store.commit("TOGGLE_LOADING");
    },
    showMessage(v, m) {
      this.message = m;
      this.snackbar = v;
    },
    checkMobile() {
      this.isMobile = screen.width <= 760;
    },
    shuffelArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    },
    async getVideos($state) {
      if (!this.loaded) {
        this.loading = true;
      }

      if (
        this.dataSearch?.city ||
        this.dataSearch?.type ||
        this.dataSearch?.location ||
        this.dataSearch?.projectTitle
      ) {
        this.changingState = true;
      }

      try {
        let videos;

        if (this.dataSearch && this.changingState) {
          videos = await VideoService.getSearchDataFromAPI(
            this.dataSearch,
            this.page
          );
        } else if (!this.dataSearch && !this.changingState) {
          videos = await VideoService.getAll(this.page);
        }

        if (videos && videos.data.data.length > 0) {
          this.videos.push(...videos.data.data);
          this.videos = this.shuffelArray(this.videos);
          if (videos.data.LastEvaluatedKey) {
            if (videos.data.LastEvaluatedKey.createts) {
              this.page = videos.data.LastEvaluatedKey.createts;
            } else {
              this.page = videos.data.LastEvaluatedKey;
            }
            $state.loaded();
            this.loaded = true;
          } else {
            this.page = "";
            $state.complete();
          }
        } else {
          $state.complete();
          //  this.$refs.infiniteLoading.forceStop();
        }
      } catch (err) {
        console.error(err);
        this.errored = true;
        $state.complete();
      } finally {
        this.loading = false;
        this.changingState = false;
      }
    },

    // async getVideos($state) {
    //   // console.log(this.videos);
    //   let videos;
    //   if (!this.loaded) {
    //     this.loading = true;
    //   }

    //   if (
    //     this.dataSearch?.city ||
    //     this.dataSearch?.type ||
    //     this.dataSearch?.location ||
    //     this.dataSearch?.projectTitle
    //   )
    //     this.changingState = true;

    //   if (this.dataSearch && this.changingState && this.videos.length == 0) {
    //     videos = await VideoService.getSearchDataFromAPI(
    //       this.dataSearch,
    //       this.page
    //     )
    //       .catch((err) => {
    //         console.log(err);
    //         this.errored = true;
    //         $state.complete();
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //         this.changingState = false;
    //         $state.loaded();
    //       });
    //   } else if (!this.dataSearch && !this.changingState) {
    //     videos = await VideoService.getAll(this.page)
    //       .catch((err) => {
    //         console.log(err);
    //         this.errored = true;
    //         $state.complete();
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //         $state.loaded();
    //       });
    //   }

    //   // console.log(videos.data.data);
    //   if (typeof videos === "undefined") return;

    //   if (videos.data.data.length > 0) {
    //     // this.videos = [];
    //     this.videos.push(...videos.data.data);

    //     if (videos.data.LastEvaluatedKey) {
    //       if (videos.data.LastEvaluatedKey.createts) {
    //         this.page = videos.data.LastEvaluatedKey.createts;
    //       } else {
    //         this.page = videos.data.LastEvaluatedKey;
    //       }
    //       $state.loaded();
    //       this.loaded = true;
    //     } else {
    //       this.page = "";
    //       $state.complete();
    //       this.loading = false;
    //     }
    //   } else {
    //     $state.complete();
    //     this.$refs.infiniteLoading.forceStop();
    //     this.loading = false;
    //   }
    // },
    async AddtoDb(s3PresignedUrl) {
      await UserService.AddtoDb(s3PresignedUrl, this.fileData)
        .then(() => {})
        .catch(() => {});
    },
    dateFormatter(date) {
      return moment(date).fromNow();
    },
  },
  components: {
    EvenVideoCard,
    // OddVideoCard,
    InfiniteLoading,
  },
};
</script>

<style lang="scss">
.card {
  background: white !important;
}

label.v-label.theme--light {
  font-size: 13px;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 50px;
}
.plyr__controls__item {
  padding: 0;
}
#home {
  background: white !important;
}

.video-js.vjs-custom-skin .vjs-big-play-button {
  background-color: rgb(126 126 126 / 45%) !important;
  border-radius: 100%;
}

@media (max-width: 480px) {
  .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -10% !important;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -7% !important;
  }
}
</style>
