import Api from '@/services/Api'
import {Auth} from 'aws-amplify';
// if (typeof window !== 'undefined') {
//   var UserData = localStorage.getItem("user");
// }
export default {
 
  async AddtoDb() {
    const user = await Auth.currentAuthenticatedUser();
  
    return Api().get("https://zpczn587s2.execute-api.ap-south-1.amazonaws.com/V1/updateregisteruser1?user_id="+user.username+"&phone="+user.attributes.phone_number+"&name="+user.attributes.name,

    {"user_id":""},
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        }
    );
  },
  

  getAll(filters) {
    return Api().get(`users/?${filters}`)
  },
  getById(id) {
    return Api().get(`users/${id}`)
  },
  createUser(data) {
    return Api().post('users', data)
  },
  updateUser(data, id) {
    return Api().put(`users/${id}`, data)
  },
  deleteById(id) {
    return Api().delete(`users/${id}`)
  }
  
}
