<template>
  <v-card
    class="content-bg card mx-auto pa-3 pt-0 card-width"
    :max-width="card.maxWidth"
    flat
    tile
    router
  >
    <v-row>
      <!-- sm="8"
      md="8"
      lg="12" -->
      <v-col
        cols="12"
        class="mt-3"
        style="border-radius: 10px; width: 100%"
        v-if="card.type != 'noAvatar'"
      >
        <v-responsive style="border-radius: 10px" class="video-container">
          <v-carousel
            hide-delimiters
            ref="carousel"
            v-if="video.Gallery"
            :continuous="false"
            class="carousel-container"
            v-model="currentIndex"
          >
            <v-carousel-item
              class="welcomepage"
              v-for="(item, index) in video.Gallery?.hlsUrl"
              :key="index"
            >
              <video-player
                v-if="item"
                :options="getVideoOptions(item)"
                class=""
              >
                <video
                  class=""
                  width="400"
                  preload="metadata"
                  crossorigin
                  controls="controls"
                  playsinline
                  :options="options"
                ></video>
              </video-player>

              <v-img
                v-else
                height="100%"
                cover
                :src="require('../assets/no-image.jpg')"
              ></v-img>
            </v-carousel-item>
            <v-carousel-item
              v-for="(item, index) in video.Gallery?.Images"
              :key="index"
            >
              <v-img v-if="item" height="100%" cover :src="item"></v-img>
              <!-- cover -->
              <v-img
                v-else
                height="100%"
                cover
                :src="require('../assets/no-image.jpg')"
              ></v-img>
            </v-carousel-item>
            <!-- <v-btn
              v-if="!isFirstVideo"
              class="custom-prev-button video-player-container"
              icon
              @click="prevCarousel"
            >
              <img :src="require('../assets/previous-button.png')" />
            </v-btn>

            <v-btn
              v-if="!isLastVideo"
              class="custom-next-button video-player-container"
              icon
              @click="nextCarousel"
            >
              <img :src="require('../assets/next-button.png')" />
            </v-btn> -->
          </v-carousel>
          <v-carousel
            v-else
            :continuous="false"
            hide-delimiters
            class="carousel-container"
          >
            <v-carousel-item>
              <v-img
                height="100%"
                cover
                :src="require('../assets/no-image.jpg')"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
          <!-- <v-carousel ref="carousel" class="carousel-container" hide-delimiters>
            <v-carousel-item
              v-for="(item, index) in video"
              :key="index"
              hide-controls
            > -->
          <!-- @ready="onPlayerReady" -->
          <!-- <video-player
                class="video-player-container"
                :options="videoOptions"
              >
                <video
                  class="video-player-container"
                  width="400"
                  controls="controls"
                  preload="metadata"
                  crossorigin
                  playsinline
                  :options="options"
                  @play="onVideoPlay"
                  @pause="onVideoPause"
                ></video>
              </video-player>
            </v-carousel-item>
            <v-btn
              v-if="!isFirstVideo && !isVideoPlaying"
              class="custom-prev-button video-player-container"
              icon
              @click="prevCarousel"
            >
              <img :src="require('../assets/previous-button.png')" />
            </v-btn>
            <v-btn
              v-if="!isLastVideo && !isVideoPlaying"
              class="custom-next-button video-player-container"
              icon
              @click="nextCarousel"
            >
              <img :src="require('../assets/next-button.png')" />
            </v-btn>
          </v-carousel> -->
        </v-responsive>

        <v-card flat tile class="card n-card">
          <v-card-title class="pl-0 pb-0" style="font-weight: 600">
            {{ video.title }}

            <!-- <a
              text
              class="deep-orange--text"
              target="_blank"
              style="letterspacing: 2px"
              :href="`https://maps.google.com/?q=${video.location} ${video.city} india`"
            >
              <v-icon class="deep-orange--text">mdi-map-marker</v-icon>
              <span class="text-black text-uppercase a1">{{
                video.location
              }}</span>
            </a> -->
            <!-- <v-btn text @click="createFeeling('like', video.videoId)">
              <v-icon class="deep-orange--text"> mdi-hand-wave </v-icon>
              <span class="text-black"> Contact </span>
            </v-btn> -->
          </v-card-title>
        </v-card>
      </v-col>
      <!-- sm="12"
      md="12"
      lg="12" -->
      <v-col cols="12" class="black-shadow2">
        <v-card class="mx-auto mb-5" flat>
          <v-row justify="space-between" no-gutters>
            <v-col cols="12" xl="6" md="6" sm="12" xs="12">
              <v-expand-transition>
                <v-list dense two-line style="padding: 0">
                  <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>title: <span class="side-text">{{ video.title }}</span></v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Property Detail:
                        <span
                          class="side-text"
                          v-if="!readMoreActivated && video.details"
                          >{{ video.details.slice(0, 10) }}
                        </span>
                        <a
                          class="side-text"
                          v-if="
                            !readMoreActivated &&
                            video.details &&
                            video.details.length > 80
                          "
                          @click="activateReadMore"
                        >
                          <span
                            class="#79869F--text text-lowercase"
                            style="
                              font-weight: 300;
                              font-size: 10px;
                              text-decoration: underline;
                            "
                            >see more</span
                          >
                        </a>
                        <span
                          class="side-text"
                          v-if="readMoreActivated && video.details"
                        >
                          <a
                            class="side-text"
                            v-if="readLessActivated"
                            @click="activateLessMore"
                          >
                            {{ video.details }}
                            <span
                              class="#79869F--text text-lowercase"
                              style="
                                font-weight: 300;
                                font-size: 10px;
                                text-decoration: underline;
                              "
                              >less</span
                            >
                          </a>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Property Type:
                        <span class="side-text">{{ video.property_type }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Sale Type:
                        <span class="side-text">{{
                          video.sale_type
                        }}</span></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >City:
                        <span class="side-text">{{
                          video.city
                        }}</span></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Locality:
                        <span class="side-text">{{
                          video.location
                        }}</span></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Numbers Of Bedrooms:
                        <span class="side-text">{{
                          video.number_of_bedrooms
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Price:
                        <span class="side-text">{{
                          video.price
                        }}</span></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </v-col>
            <v-col cols="12" xl="6" md="6" sm="12" xs="12">
              <v-expand-transition>
                <v-list dense two-line style="padding: 0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Area:
                        <span class="side-text">{{
                          video.area
                        }}</span></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Availability:
                        <span class="side-text">{{ video.availability }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Listing Type:
                        <span class="side-text">{{ video.listing_type }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Amentities / Attractions:

                        <span class="side-text" v-if="!readMoreActivated2"
                          >{{ video.amenities_attractions.slice(0, 80) }}
                        </span>
                        <a
                          class="side-text"
                          v-if="
                            !readMoreActivated2 &&
                            video.amenities_attractions.length > 80
                          "
                          @click="activateReadMore2"
                        >
                          <span
                            class="#79869F--text text-lowercase"
                            style="
                              font-weight: 300;
                              font-size: 10px;
                              text-decoration: underline;
                            "
                            >see more</span
                          >
                        </a>
                        <span class="side-text" v-if="readMoreActivated2">
                          <a
                            class="side-text"
                            v-if="readLessActivated2"
                            @click="activateLessMore2"
                          >
                            {{ video.amenities_attractions }}
                            <span
                              class="#79869F--text text-lowercase"
                              style="
                                font-weight: 300;
                                font-size: 10px;
                                text-decoration: underline;
                              "
                              >less</span
                            >
                          </a>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="video.additional_information">
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Additional Information:

                        <span
                          class="side-text"
                          v-if="
                            !readMoreActivated3 && video.additional_information
                          "
                          >{{ video.additional_information.slice(0, 80) }}
                        </span>
                        <a
                          class="side-text"
                          v-if="
                            !readMoreActivated3 &&
                            video.additional_information &&
                            video.additional_information.length > 80
                          "
                          @click="activateReadMore3"
                        >
                          <span
                            class="#79869F--text text-lowercase"
                            style="
                              font-weight: 300;
                              font-size: 10px;
                              text-decoration: underline;
                            "
                            >see more</span
                          >
                        </a>
                        <span class="side-text" v-if="readMoreActivated3">
                          <a
                            class="side-text"
                            v-if="readLessActivated3"
                            @click="activateLessMore3"
                          >
                            {{ video.additional_information }}
                            <span
                              class="#79869F--text text-lowercase"
                              style="
                                font-weight: 300;
                                font-size: 10px;
                                text-decoration: underline;
                              "
                              >less</span
                            >
                          </a>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="property-keys-list"
                        >Uploaded By:
                        <span class="side-text">{{ video.user_name }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </v-col>
          </v-row>
          <v-row class="ml-1">
            <!-- button for location -->
            <v-btn
              variant="tonal"
              color="#FBA388"
              class="mr-5 mb-3 white--text"
              style="max-width: 50%"
              :href="`https://maps.google.com/?q=${video.location} ${video.city} india`"
              target="_blank"
              elevation="0"
            >
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-1"
              >
                <path
                  d="M6.43785 7.15559C6.81745 7.15559 7.14251 7.02032 7.41306 6.74977C7.68315 6.47969 7.81819 6.15485 7.81819 5.77525C7.81819 5.39566 7.68315 5.07059 7.41306 4.80005C7.14251 4.52996 6.81745 4.39492 6.43785 4.39492C6.05826 4.39492 5.73342 4.52996 5.46333 4.80005C5.19279 5.07059 5.05752 5.39566 5.05752 5.77525C5.05752 6.15485 5.19279 6.47969 5.46333 6.74977C5.73342 7.02032 6.05826 7.15559 6.43785 7.15559ZM6.43785 14.0573C4.5859 12.4814 3.2028 11.0175 2.28856 9.66574C1.37386 8.31439 0.916504 7.06357 0.916504 5.91329C0.916504 4.18787 1.47163 2.81328 2.58188 1.78953C3.69167 0.765781 4.977 0.253906 6.43785 0.253906C7.89871 0.253906 9.18403 0.765781 10.2938 1.78953C11.4041 2.81328 11.9592 4.18787 11.9592 5.91329C11.9592 7.06357 11.5021 8.31439 10.5878 9.66574C9.67313 11.0175 8.2898 12.4814 6.43785 14.0573Z"
                  fill="white"
                />
              </svg>

              <span
                class="text-capitalize"
                style="font-size: 80%; font-weight: 300"
                >{{ video.city }}</span
              >
            </v-btn>
            <!-- button for contact -->
            <v-btn
              variant="tonal"
              color="#FBA388"
              class="white--text"
              elevation="0"
              style="max-width: 50%"
              @click="createFeeling('like', video.videoId)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="24"
                viewBox="0 0 24 24"
                class="mr-1"
              >
                <path
                  fill="white"
                  d="m12 22l-.25-3h-.25q-3.55 0-6.025-2.475T3 10.5q0-3.55 2.475-6.025T11.5 2q1.775 0 3.313.662t2.7 1.825q1.162 1.163 1.824 2.7T20 10.5q0 1.875-.613 3.6t-1.675 3.2q-1.062 1.475-2.525 2.675T12 22Zm-.525-6.025q.425 0 .725-.3t.3-.725q0-.425-.3-.725t-.725-.3q-.425 0-.725.3t-.3.725q0 .425.3.725t.725.3ZM10.75 12.8h1.5q0-.75.15-1.05t.95-1.1q.45-.45.75-.975t.3-1.125q0-1.275-.863-1.913T11.5 6q-1.1 0-1.85.613T8.6 8.1l1.4.55q.125-.425.475-.838T11.5 7.4q.675 0 1.012.375t.338.825q0 .425-.25.763t-.6.687q-.875.75-1.063 1.188T10.75 12.8Z"
                />
              </svg>
              <span
                class="text-capitalize"
                style="font-size: 80%; font-weight: 300"
              >
                Contact
              </span>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import FeelingService from "@/services/FeelingService";
import VideoService from "../services/VideoService";

export default {
  name: "EvenVideoCard",
  props: {
    video: {
      type: Object,
      required: true,
    },
    card: Object,
  },
  data() {
    // console.log("h1", this.video.thumbnail_url[0]);
    // console.log("h1l", this.video.hls_url);

    // height: "176.75px",
    // aspectRatio: "4:3",
    return {
      videoOptions: {
        height: "500px",
        autoplay: false,
        responsive: true,
        fluid: false,
        source: {
          id: this.video.videoId,
          type: "application/x-mpegURL",
          src: this.video.hls_url,
          responsive: true,
          // if you need custom player state changed event name, you can config it like this
          customEventName: "my-player-state-changed-event-custom-name",
        },
        poster:
          this.video.thumbnail_url && this.video.thumbnail_url.length
            ? this.video.thumbnail_url[0]
            : null,
      },
      isVideoPlaying: false,
      currentIndex: 0,

      differentBucketVideos: {},
      readMoreActivated: false,
      readLessActivated: true,

      readMoreActivated2: false,
      readLessActivated2: true,

      readMoreActivated3: false,
      readLessActivated3: true,
      show: false,
      url: process.env.VUE_APP_URL,
      feeling: "",
      options: {
        fullscreen: {
          enabled: false,
          fallback: true,
          iosNative: false,
          container: null,
        },
      },

      galleryItems: [
        ...this.video.Gallery?.Images,
        ...this.video.Gallery?.Videos,
      ],

      hide: false,
    };
  },
  created() {
    this.isMobile();
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth < 768;
    },
    isFirstVideo() {
      return this.currentIndex == 0;
    },
    isLastVideo() {
      return (
        this.currentIndex ==
        (this.video.Gallery?.Videos
          ? this.video.Gallery?.Videos.length - 1
          : this.video.Gallery?.Images.length - 1)
      );
    },
  },
  // mounted() {
  //   // Set fluid option if on small screen
  //   if (this.isSmallScreen) {
  //     this.videoOptions.fluid = true;
  //     console.log(this.videoOptions);
  //   }
  // },

  beforeMount() {
    //   this.getDifferentBucketVideos();
    if (this.isSmallScreen) {
      this.videoOptions.fluid = true;
    }
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    activateLessMore() {
      this.readMoreActivated = false;
      this.readLessActivated = true;
    },
    activateReadMore2() {
      this.readMoreActivated2 = true;
    },
    activateLessMore2() {
      this.readMoreActivated2 = false;
      this.readLessActivated2 = true;
    },
    activateReadMore3() {
      this.readMoreActivated3 = true;
    },
    activateLessMore3() {
      this.readMoreActivated3 = false;
      this.readLessActivated3 = true;
    },

    prevCarousel() {
      if (!this.isFirstVideo) {
        this.$refs.carousel.prev();
        this.currentIndex--;
      }
    },
    nextCarousel() {
      if (!this.isLastVideo) {
        this.$refs.carousel.next();
        this.currentIndex++;
      }
    },
    onVideoPlay() {
      console.log(this.isVideoPlaying);
      this.isVideoPlaying = true;
    },
    onVideoPause() {
      this.isVideoPlaying = false;
    },
    isMobile() {
      this.show = screen.width <= 760;
    },
    dateFormatter(date) {
      return moment(date).fromNow();
    },
    async createFeeling(type, videoId) {
      if (!this.$store.getters.isAuthenticated) {
        this.error = "Password updated successfully";
        this.snackbar = true;

        this.$fire({
          text: "Please sign in /create free account to gain access to all EndWait features",
          timer: 3000,
        }).then((r) => {
          console.log(r.value);
        });

        /*    this.$emit('input', true,'Please sign in /create free account to gain access to all EndWait features')
            //this.$router.push('signin')
            this.signinDialog = true
            this.details = {
              title:
                  type === 'like' ? 'Like this video?' : "Don't like this video?",
              text: 'Please sign in /create free account to gain access to all EndWait features'
            }
            return */
      }
      switch (true) {
        case type === "like" && this.feeling === "":
          this.feeling = "like";
          this.video.likes++;
          break;
        case type === "like" && this.feeling === type:
          this.feeling = "";
          this.video.likes--;
          break;
        case type === "like" && this.feeling === "dislike":
          this.feeling = "like";
          this.video.dislikes--;
          this.video.likes++;
          break;
        case type === "dislike" && this.feeling === "":
          this.feeling = "dislike";
          this.video.dislikes++;
          break;
        case type === "dislike" && this.feeling === type:
          this.feeling = "";
          this.video.dislikes--;
          break;
        case type === "dislike" && this.feeling === "like":
          this.feeling = "dislike";
          this.video.likes--;
          this.video.dislikes++;
      }

      await FeelingService.createFeeling(videoId)
        .then((resp) => {
          console.log(resp);
          this.$fire({
            text: resp.data.body,
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        })
        .catch((error) => {
          this.$fire({
            text: error.response.data.body,
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        });
    },
    async getDifferentBucketVideos() {
      try {
        await VideoService.getDifferentVideoAndImageList();
      } catch (error) {
        console.log("bye");
      }
    },
    getVideoOptions(item) {
      let mp4OrNot = item.substr(-3);

      return {
        height: "500px",
        autoplay: false,
        responsive: true,
        fluid: false,
        source: {
          id: this.video.videoId,
          type: mp4OrNot == "mp4" ? "video/mp4" : "application/x-mpegURL",
          //"video/mp4" ||
          //"video/x-flv" ||
          //"application/x-mpegURL" ||
          //"video/quicktime" ||
          //"video/x-msvideo",
          src: item,
          responsive: true,
          customEventName: "my-player-state-changed-event-custom-name",
        },
        poster: this.video.Gallery.thumbNailsUrl
          ? this.video.Gallery.thumbNailsUrl
          : null,
      };
    },
    // getBaseUrl(url) {
    //   const parts = url.split("?");
    //   return parts[0];
    // },
    // onPlayerReady(player) {
    //   console.log("player----------------", player);
    //   // Store the player instance in a data property
    //   this.player = player;
    // },
    // playVideo() {
    //   // Call the play() method on the player instance to start playing the video
    //   if (this.player) {
    //     this.player.play();
    //   }
    // },
  },
};
</script>

<style>
a:hover {
  text-decoration: none !important;
}
.welcomepage > .video-js.vjs-custom-skin .vjs-big-play-button {
  margin-left: -5% !important;
}
.welcomepage > .video-js.vjs-custom-skin .vjs-big-play-button {
  background-color: rgb(126 126 126 / 45%) !important;
  border-radius: 100%;
}
</style>
<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit !important;
}

.text-black {
  color: rgb(0, 0, 0);
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0;
}

.side-text {
  font-weight: 700;
  color: #79869f;
}

.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: auto !important;
  padding: 0;
}

.v-list-item {
  margin-bottom: 0.5rem;
}

.card-width {
  width: 70%;
}

.property-keys-list {
  color: #79869f;
}

@media (max-width: 480px) {
  .card-width {
    width: 95%;
  }
  .n-card .spacer {
    display: block;
    width: 100%;
  }

  .n-card .v-card__title {
    font-size: 1.1em;
    padding: 0 !important;
  }

  .n-card .v-icon.v-icon {
    font-size: 1.2em;
  }

  .n-card .v-btn__content span {
    font-size: 1em;
  }

  .n-card a {
    padding: 0 !important;
    padding-right: 2em !important;
  }

  .n-card {
    padding: 0 !important;
    padding-top: 0.5rem !important;
  }

  .v-application .mt-8 {
    margin-top: 1rem !important;
  }

  .deep-orange--text {
    color: fb5723 !important;
    font-weight: bold !important;
  }

  span.deep-orange--text {
    color: black !important;
    font-weight: bold !important;
  }
}
</style>
<style>
.video-js .vjs-big-play-button {
  width: 2em !important;
}
</style>
